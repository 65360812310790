import { baseAssetsStore } from '@/store/assets'
import { virtualEnum } from '@/constants/common'
import { BusinessidEnum } from '@/constants/assets/index'
import { oss_ra_merchant_config_address } from '@/constants/oss'
import axios from 'axios'

/**
 * 判断当前是否是单法币模式
 */
export function getIsSingleFaitMode() {
  const userStore = baseAssetsStore.getState()
  return userStore?.merchantInfo?.virtual === virtualEnum.FiatCurrency
}

/**
 * 获取bid Json数据
 */
export async function getFastManyBusiness() {
  const listBid = [BusinessidEnum.digitId, BusinessidEnum.fecomId]
  const url = `${oss_ra_merchant_config_address}merchantConfig.json`
  try {
    const res = await axios.get(url)
    const dataConcat = res?.data?.MerchantProduct?.concat(res?.data?.MerchantTest)
    return dataConcat
  } catch (_error) {
    return listBid
  }
}
